import React from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {IState} from "../../types/state";
import {domainUrl} from "../../helper";
import Image from "next/image";
import cls from "./shared.module.scss"

interface ISocialLinksProps {
    shape: string
    className: string;
    dbName?: string
}

const SocialLinks: React.FC<ISocialLinksProps> = ({shape, className, dbName}) => {
    const dbNameCopy = useSelector((state: IState) => state.general.dbName)
    const DBName = dbName ?? dbNameCopy

    const classes = classNames(className, cls["social-links"], {
        [cls["social-links--shape--circle"]]: shape === "circle",
        [cls["social-links--shape--rounded"]]: shape === "rounded"
    });
    const social = useSelector((state: IState) => state["general"].social)


    let socialLinks;
    if (social) {
        socialLinks = social.map((item) => (
            <li className={cls["social-links__item"]} key={item.name}>
                <a
                    className={`${cls["social-links__link"]} ${cls["social-link-color"]}`}
                    href={item.link}
                    aria-label="socials"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Image
                        src={domainUrl(`${DBName}/storage/${item.icon}`)}
                        alt={item.name}
                        placeholder="blur"
                        layout="fill"
                        blurDataURL={`/zega/blur1.webp`}
                        objectFit='contain'
                    />
                </a>
            </li>
        ));
    }

    return (
        <div className={classes}>
            <ul className={cls["social-links__list"]}>{socialLinks}</ul>
        </div>
    );
}


export default React.memo(SocialLinks);
